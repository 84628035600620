import React from "react"

import SEO from "../components/seo"
import rond_color from "../assets/rond_color.svg"

import Navigation from "../components/Navigation"


const PAFW = () => (
    <div
        className="containerManifesto"
      >
        <div className=" container containerHome">
        <Navigation/>
        {/*<div className="rondTache row">
          <img src={rond} className="col-3 rond"/>
          <img src={tache} className="col-9 tache"/>
        </div>*/}
    <div>
    <SEO title="Plaiz - Plaiz Anti Fashion Week - Plaiz, le réseau social de la mode"/>
    <div>
    <h1 className="p1">La Plaiz Anti Fashion Week #PAFW</h1>

    <h4
      style={styles.backToHomeLink}
    >
    <p className="textChangeGame"><br/>
    <b><i></i></b>
            <br/>
            <i></i><br/>
            <br/>"On a vu tellement de choses, des défilés, des performances live (danseurs, rappeurs, speed graffiti...), des cocktails, des soirées... Mais tout ça loin des strass et paillettes, du glamour et des fausses bonnes manières." <i>- Un participant</i>
            <br/>
            <br/>La Plaiz Anti Fashion Week <b>(#PAFW)</b> vous plonge pendant une semaine au coeur de l'univers underground et original des marques préférées du <b>Plaiz Gang</b>
            <br/>
            <br/>Une fashion week contre-culture, secrète, aux frontières de Paris, cachée dans <b> un hangar de 2000m2</b> aménagé pour l'occasion.
            <br/>
            <br/>Une fashion week électrique, réservée à ceux qui n'ont peur de rien. Une fashion week où la mode est rendue fun à nouveau, tout en restant un art. Mais une fashion week où nous sommes tous des artistes à notre manière.
            <br/>
            <br/>
            <br/>
            <br/>
            <a target="#" href="https://www.youtube.com/watch?v=KZ8jonNATQs" className="textChangeGame"><b> >> Trailer </b></a><br/>
            <br/>
            <a target="#" href="https://www.youtube.com/watch?v=Kzhp1gD3GNM" className="textChangeGame"><b> >> Aftermovie</b></a><br/>
            <br/>
            <br/>
            <br/>
        </p>
    </h4>
    </div>
    </div>
    </div>
    </div>
)

export default PAFW

const styles = {
  backToHomeLink: {
    color: "white",
  },
}